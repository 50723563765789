import React from "react"
import styled from "styled-components"
import { TimeIcon } from "../../assets/icons/TimeIcon"
import { CellularIcon } from "../../assets/icons/CellularIcon"
import { PersonIcon } from "../../assets/icons/PersonIcon"
import { StarIcon } from "../../assets/icons/StarIcon"

const MetaStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  padding: 12px;

  background-color: hsl(217, 90%, 91%);
  border-radius: 0 0 8px 8px;

  p {
    display: flex;
    align-items: center;
    padding-left: 30%;
    font-size: 0.9rem;

    svg {
      width: 0.9rem;
      height: 0.9rem;

      padding-right: 12px;
    }
  }
`

export default function MetaBanner({
  anzahlTeilnehmer,
  distanz,
  schwierigkeit,
  alter,
  bewertung,
  dauer,
}) {
  return (
    <MetaStyle>
      <p>
        <PersonIcon />
        {anzahlTeilnehmer}
      </p>
      <p>
        <TimeIcon />
        {dauer}
      </p>
      <p>
        <CellularIcon />
        {schwierigkeit}
      </p>
      <p>{distanz}</p>
      <p>{alter}</p>
      {/*<p>{bewertung}*</p>*/}
      <p>
        <StarIcon fill={"black"} />
        <StarIcon fill={"black"} />
        <StarIcon fill={"black"} />
        <StarIcon fill={"black"} />
        <StarIcon />
      </p>
    </MetaStyle>
  )
}
