import React, { useEffect, useState } from "react"

/**
 * @param {Array.<Array>.<Number, Number>} path - The coords  that create a path
 * @param {Array.<Number, Number>} center - Determines the center of the map
 * @param {number} [zoom=9] - Determines the zoom level
 * @param {Array.<{lat: Number, long: Number, text: String}>}  [markers] - markers
 * @param {number} [height] - height of the map
 * @param {Array} paths - array of paths
 */
export default function Map({
  path,
  center,
  zoom = 9,
  markers,
  height = 300,
  paths,
  mapId,
}) {
  // create random id, in case there are multiple maps in one report
  const id = "reportMap" //mapId
  const [map, setMap] = useState(false)

  useEffect(() => {
    console.log("MAP.js")
    console.log(path, paths)

    if (typeof window !== `undefined`) {
      const L = require("leaflet")
      if (document.getElementById(id)) {
        // const markerIcon = require("../../assets/leaflet/markerIcon")

        // create map object if not existent
        // TODO I think this slows down the map loading speed, as it requires..
        // useEffect to be called again.
        // if set locally, hot reloading doesn't work bc of "Map container already initialized"
        if (!map) {
          setMap(
            L.map(id, {
              center: center, // center = Alzey
              zoom: zoom,
            })
          )
          return
        }

        // create path
        if (path) {
          L.polyline(path, { color: "red" }).addTo(map)
        }

        // create markers
        if (markers) {
          markers.forEach(marker => {
            const newMarker = L.marker([marker.lat, marker.long], {
              // icon: markerIcon,
            })
            newMarker.bindPopup(marker.text)
            newMarker.addTo(map)
          })
        }

        if (paths) {
          paths.forEach(path => {
            const pl = L.polyline(path, { color: "red" })

            pl.bindPopup(
              "<a href='https://altburgund.de/berichte/lohninger-pfingstfahrt/'>Lohninger Pfingstfahrt Bericht</a>"
            )
            pl.bindTooltip("Lohninger Pfingstfahrt")
            pl.on("click", () => {
              console.log("click")
              // var popup = new L.Popup()
              // var bounds = pl.getBounds()
              // var popupContent = "popup content here"
              // popup.setLatLng(bounds.getCenter())
              // popup.setContent(popupContent)
              // map.openPopup(popup)
            })
            pl.addTo(map)
          })
        }

        // apply tile layer
        // other tile layers: streets-v10, sattelite, ... replace in URL
        L.tileLayer(
          "https://api.mapbox.com/styles/v1/mapbox/outdoors-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibGhvYyIsImEiOiJjanFtejFxcWI0cmVpNDNqd3l4djZqM3Y2In0.6VKppk4HiyvBr9SbyjW_uQ",
          {
            maxZoom: 18,
            // attribution has (also legally) to be given
            attribution:
              'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, ' +
              '<a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
              'Imagery © <a href="http://mapbox.com">Mapbox</a>',
          }
        ).addTo(map)
      }
    }
  }, [id, path, center, zoom, markers, height, paths, map])

  return (
    <div
      className={"map"}
      id={"reportMap"}
      style={{ height: `${height}px`, borderRadius: "8px 8px 0 0" }}
    />
  )
}
