import styled from "styled-components"
import { container } from "../../constants/container"
import fontSize from "../../constants/typeScale"
import { spacing } from "../../constants/spacing"
import colors from "../../constants/colors"

export const Banner = styled.section`
  display: flex;
  flex-direction: column;
  // align-items: center;
  max-width: ${container.medium};
  margin: 0 auto;
  padding: 0 ${spacing.spaceSm};

  font-family: "Montserrat", sans-serif;

  h1 {
    margin-bottom: ${spacing.spaceXs};

    font-size: ${fontSize.text3xl};
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: ${spacing.space7xl};
    display: none;
  }

  .imageCredit {
    color: ${colors.greys["500"]};
    font-size: ${fontSize.textSm};
    text-align: center;
  }
`

export const Meta = styled.div`
  display: flex;

  * + span {
    padding: 0 ${spacing.spaceSm} !important;
  }

  span {
    box-sizing: border-box;
    padding: 0 ${spacing.spaceSm} 0 0;
  }
`

export const ReportBody = styled.section`
  max-width: ${container.medium};
  margin: ${spacing.spaceXl} auto;
  padding: 0 ${spacing.spaceSm};

  font-size: ${fontSize.textXl};
  font-family: "Montserrat", sans-serif;

  font-family: "PT Serif", serif;
  text-rendering: optimizeLegibility;
  color: hsl(0, 5%, 20%);
  line-height: 1.44em;
  letter-spacing: 0.022em;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Montserrat", sans-serif;
  }

  a {
    text-decoration: none;
    color: ${colors.links.default};

    &:hover,
    &:focus {
      text-decoration: ${colors.links.default} underline dashed;
    }
  }

  ul {
    list-style: none;
  }

  li {
    &:before {
      content: "-";
      color: ${colors.indigo.dark};
      width: 1em;
      font-weight: bold;
      display: inline-block;
      margin-left: -1em;
    }
  }
`

export const ImageTitle = styled.span`
  display: block;
  width: 100%;

  text-align: center;
  color: ${colors.greys["500"]};
  font-size: ${fontSize.textSm};
`
