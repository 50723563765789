import React from "react"

export const CellularIcon = ({
  fill,
  stroke,
  strokeLinecap,
  strokeLineJoin,
  strokeWidth,
}) => {
  const style = {
    fill: fill || "none",
    stroke: stroke || "#000",
    strokeLinecap: strokeLinecap || "round",
    strokeLineJoin: strokeLineJoin || "round",
    strokeWidth: strokeWidth || "32px",
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <title>Schwierigkeit</title>
      <rect
        x="416"
        y="96"
        width="64"
        height="320"
        rx="8"
        ry="8"
        style={style}
      />
      <rect
        x="288"
        y="176"
        width="64"
        height="240"
        rx="8"
        ry="8"
        style={style}
      />
      <rect
        x="160"
        y="240"
        width="64"
        height="176"
        rx="8"
        ry="8"
        style={style}
      />
      <rect
        x="32"
        y="304"
        width="64"
        height="112"
        rx="8"
        ry="8"
        style={style}
      />
    </svg>
  )
}
