import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"
import Layout from "../../components/Layout"
import { Link } from "gatsby"
import { Banner, Meta, ReportBody } from "./styled"
import Map from "../../components/fahrtenatlas/Map"
import MetaBanner from "../../components/fahrtenatlas/MetaBanner"
const shortcodes = { Link, Map, MetaBanner } // Provide common components here

export default function PageTemplate({ data: { mdx } }) {

  const options = { year: "numeric", month: "long", day: "numeric" }
  // noinspection JSUnresolvedVariable
  return (
    <Layout>
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.0.1/dist/leaflet.css"
      />
      <Banner>
        <Img fluid={mdx.frontmatter.metaImage.childImageSharp.fluid} />
        <span className={"imageCredit"}>{mdx.frontmatter.imageCredit}</span>
        <h1>{mdx.frontmatter.title}</h1>
        <Meta>
          <span>
            {new Intl.DateTimeFormat("en-US", options).format(
              new Date(mdx.frontmatter.date)
            )}
          </span>
          |<span> {mdx.frontmatter.tags}</span>
        </Meta>
      </Banner>
      <ReportBody>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </ReportBody>
    </Layout>
  )
}
// noinspection JSUnusedGlobalSymbols
export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        date
        tags
        metaImage {
          childImageSharp {
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
