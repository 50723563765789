import React from "react"

export const PersonIcon = ({
  fill,
  stroke,
  strokeLinecap,
  strokeLineJoin,
  strokeWidth,
}) => {
  const style = {
    fill: fill || "none",
    stroke: stroke || "#000",
    strokeLinecap: strokeLinecap || "round",
    strokeLineJoin: strokeLineJoin || "round",
    strokeWidth: strokeWidth || "32px",
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <title>Anzahl Teilnehmer</title>
      <path
        d="M314.21,482.32,257.44,367.58l-44.89-57.39a72.82,72.82,0,0,1-10.13-37.05V144h15.67a40.22,40.22,0,0,1,40.23,40.22V367.58"
        style={style}
      />
      <path d="M127.9,293.05V218.53S165.16,144,202.42,144" style={style} />
      <line x1="370.1" y1="274.42" x2="304" y2="231" style={style} />
      <line x1="170.53" y1="478.36" x2="224" y2="400" style={style} />
      <circle cx="258.32" cy="69.48" r="37.26" style={style} />
    </svg>
  )
}
